export enum ESortDirections {
  Up = 'up',
  Down = 'down',
}

export enum ESortProductDirections {
  Default = 'default',
  PriceAsc = 'price-asc',
  PriceDsc = 'price-dsc',
  AlphaAsc = 'alpha-asc',
  AlphaDsc = 'alpha-dsc',
}
